


import axios from 'config/axios';

export const signIn = async (data = {}) => {
  try {
    return await axios.post('auth/login', {
      ...data
    });
  } catch (error) {
    console.error(error);

  }
};

export const getProfile = async () => {
  try {
    return await axios.get('users/info');
  } catch (error) {
    console.error(error);

  }

};