import { atom } from "recoil";

export const userState = atom({
  key: 'user',
  default: null,
});
export const languagesState = atom({
  key: 'languages',
  default: []
});

export const translationsState = atom({
  key: 'translations',
  default: {

  }
});
export const ratingsState = atom({
  key: 'ratings',
  default: {

  }
});

export const pagesState = atom({
  key: 'pages',
  default: [],
});






export const optionsState = atom({
  key: 'options',
  default: [],
});

export const ordersState = atom({
  key: 'orders',
  default: [],
});



export const faqState = atom({
  key: 'faq',
  default: [],
});

export const timersState = atom({
  key: 'timers',
  default: [],
});

export const countdownsState = atom({
  key: 'countdowns',
  default: [],
});

export const currenttimeState = atom({
  key: 'times',
  default: [],
});

export const currenttimeoptionsState = atom({
  key: 'currenttimeoptions',
  default: {
    changed: false,
    timeType: "all_hours",
    hideSeconds: false,
    hideTimezone: false,
    clockType: 'digital'
  }
});

export const timerInfoState = atom({
  key: 'timerinfo',
  default: {
    paused: false,
    started: false
  },
});
export const alarmsState = atom({
  key: 'alarms',
  default: [],
});
export const stopwatchState = atom({
  key: 'stopwatch',
  default: {
    currenttime: null,
    history: []
  },
});

export const startedStopwatchState = atom({
  key: 'startedStopwatch',
  default: {
    started: false,
    laped: false
  }
});

export const embedAlarmState = atom({
  key: 'embedAlarm',
  default: {}
});



