import '../styles/globals.scss';
import '../styles/flexy.scss';
import App from 'next/app';
import { useRecoilSnapshot } from 'recoil';
import { useEffect } from 'react';
import { RecoilRoot } from 'recoil';
import { NextIntlProvider, IntlErrorCode } from 'next-intl'
import { get } from 'api';
import Script from "next/script"

function DebugObserver() {
  const snapshot = useRecoilSnapshot();
  useEffect(() => {
    console.log('The following atoms were modified:');
    for (const node of snapshot.getNodes_UNSTABLE({ isModified: true })) {
      console.log(node.key, snapshot.getLoadable(node));
    }
  }, [snapshot]);

  return null;
}

import { AppContextProvider } from 'contexts/app';

export function reportWebVitals(metric) {
  // console.log(metric)
}
function onError(error) {
  if (error.code === IntlErrorCode.MISSING_MESSAGE) {
    // Missing translations are expected and should only log an error
    // console.error(error);
  }
}

function OnlineToolApp({ Component, pageProps }) {

  return (

    <>
      <Script strategy="afterInteractive" src="https://www.googletagmanager.com/gtag/js?id=G-9RCBVP24PP" />
      <Script
        id='google-analytics'
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: ` window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-9RCBVP24PP');`,
        }}
      />
      <NextIntlProvider onError={onError} locale={pageProps.locale} messages={JSON.parse(pageProps.messages)}>
        <RecoilRoot>
          {/*<DebugObserver />*/}
          <AppContextProvider>
            <Component {...pageProps} />
          </AppContextProvider>
        </RecoilRoot>
      </NextIntlProvider>
    </>
  )
}



OnlineToolApp.getInitialProps = async (appContext) => {

  const { ctx } = appContext;

  const lang_key = ctx.req.headers.host.split('.')[0] === 'timertone' ? 'en' : ctx.req.headers.host.split('.')[0];

  const page = await get(`public/translations?lang=${lang_key}`);

  //console.log("===lang_key====", lang_key);
  // calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(appContext);
  //const { ctx } = appContext;

  //const { data: headerCategories } = await get('category?page=0&limit=100&order=ASC&orderBy=order');
  // const { data: countries } = await get('brands/made_by');
  //const { data } = await get(`locals/${ctx?.locale || 'en'}`);
  //const { id, ...messages } = data || {};

  return {
    ...appProps,
    pageProps: {
      messages: JSON.stringify(page.data.payload),
      locale: lang_key
    }
  }
};



export default OnlineToolApp;


