
import axios from 'config/axios';
const apiUrl = process.env.NEXT_PUBLIC_API_URL;


async function create(url = '', data = {}) {
    return await axios.post(`${apiUrl}${url}`, {
        ...data
    });
}

async function update(url = '', data = {}) {
    return await axios.put(`${apiUrl}${url}`, {
        ...data
    });
}

async function get(url = '') {

    return await axios.get(`${apiUrl}${url}`);

}

async function remove(url = '') {
    return await axios.delete(`${apiUrl}${url}`);

}

async function upload(formData, token) {
    const response = await fetch(`${apiUrl}files/upload`, {
        method: 'POST',
        headers: {
            'x-auth-token': token,
        },
        body: formData
    });

    return response.json();
}

export {
    upload,
    create,
    update,
    get,
    remove
};
