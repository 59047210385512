import axios from 'axios';




const axiosInstant = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_API_URL}`,
});

axiosInstant.interceptors.request.use(config => {

  const token = (typeof window !== "undefined") ? window.localStorage.getItem('token') : null;

  if (token && token !== 'undefined' && token !== 'null') {
    config.headers['x-api-token'] = token.replace(/"/g, '');
  }


  return config;
});

export default axiosInstant;
