// contexts/auth.js

import React, { createContext, useState, useContext, useEffect } from 'react'



//api here is an axios instance which has the baseURL set according to the env.
//import api from '../services/Api';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { userState, languagesState, pagesState } from 'store/atoms';
import { useRouter } from 'next/router';
import { getProfile } from 'api/admin/user';
import { get } from 'api/index';
import { useLocalStorage } from "hooks/localstorage";

import Spinner from 'components/spinner';
const AppContext = createContext({});

export const AppContextProvider = ({ children }) => {

    //const [user, setUser] = useState(null);

    const [loading, setLoading] = useState(true);
    const [token, setToken] = useLocalStorage("token", null);
    const user = useRecoilValue(userState);
    const setUser = useSetRecoilState(userState);
    const languages = useRecoilValue(languagesState);
    const setLanguages = useSetRecoilState(languagesState);

    const pages = useRecoilValue(pagesState);
    const setPages = useSetRecoilState(pagesState);



    useEffect(async () => {
        try {
            setLoading(true);
            if (token && !user) {
                const profile_info = await getProfile();
                setUser(profile_info.data.payload);
            }
            if (!languages.entities) {
                const languages = await get('manager/languages');
                setLanguages(languages.data.payload);
            }
            /* if (!translations.entities) {
                 const translations = await get(`manager/translations`);
                 setTranslations(translations.data.payload);
             }*/
            if (!pages.entities) {
                const pages = await get(`manager/pages`);
                setPages(pages.data.payload);
            }

            setLoading(false);
        } catch (error) {
            alert(error);
        }

    }, [])

    const login = async (email, password) => {
        const { data: token } = await api.post('auth/login', { email, password })
        if (token) {
            console.log("Got token")
            Cookies.set('token', token, { expires: 60 })
            api.defaults.headers.Authorization = `Bearer ${token.token}`
            const { data: user } = await api.get('users/me')
            setUser(user)
            console.log("Got user", user)
        }
    }

    const logout = (email, password) => {
        Cookies.remove('token')
        setUser(null)
        delete api.defaults.headers.Authorization
        window.location.pathname = '/login'
    }


    return (
        <AppContext.Provider value={{ isAuthenticated: !!user, user, login, loading, logout }}>
            {children}
        </AppContext.Provider>
    )
}




export const useAuth = () => useContext(AppContext)


export const ProtectRoute = ({ children }) => {
    const router = useRouter();
    const { isAuthenticated, isLoading } = useAuth();
    if (isLoading || (!isAuthenticated && router.pathname !== '/login')) {
        return <Spinner />;
    }
    return children;
};